/* On screens that are 992px or less, set the background color to blue */
@media screen and (max-width: 992px) {
  .container-custom {
      width: 95%;
      margin: auto;
      /* width: 98%;
      max-width: 960px;
      margin: 0px auto;
      display: inline-block; */
    
    }
    
}

/* On screens that are 600px or less, set the background color to olive */
@media screen and (max-width: 600px) {
  .container-custom {
      width: 98%;
      margin: auto;
      /* width: 98%;
      max-width: 960px;
      margin: 0px auto;
      display: inline-block; */
    
    }
    .section-1 img {
      /* width: 520px;
      height: 78px; */
      width: 75%;
    }
}