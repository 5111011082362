body,
.container-custom {
  background-color: #261515 !important;
  color:white
  
}

.container-custom {
  width: 80%;
  margin: auto;

}




/* ====== by mohit====== */
.table {
  overflow-x: auto;
}

/* body {
  background-color: rgb(242, 242, 242);
} */

.section-1 {
  text-align: center;
 
}

.section-1 img {
  width: 100%;
  height: 100px;
  padding: 1% 0%
}
.navbar_1{
  background-color: #9ea1a0;
  padding: 6px 4px 6px 4px;
  height: 60px;
  width: 100%;
  border-radius:5px ;
  border-bottom: 3px solid rgb(82, 75, 90);
  /* text-align: center; */
  display: flex;
  justify-content: center;
  align-items: center;
}
 /* .section-1 a img[alt]{
  color: white !important;
  /* color: white; */
  /* background-color: white;
  border-radius: 10px;
 } */ 

.content-heading {
  vertical-align: middle;
  font-size: 1.2em;
  color: #fff;
  /* margin: 0 8px; */
  border: 1px solid #396;
  background-color: #3c9;
  /* line-height: 1.4; */
  padding: 0.5rem;
}


/* div on each pera will do later  */

.s1-p1 {
  font-size: 13px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

/* .container {
  width: 63%;
  margin: auto;
} */

.container-1 {
  text-align: center;
}

.s1-p2 {
  font-size: small;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  color: rgb(210, 210, 215);
  padding: 8px 0;
  background-color: rgb(58, 40, 40);
  border-radius: 5px;
}

.s1-p3 {
  /* font-size: 16px; */
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  color: rgb(210, 210, 215);
  padding: 8px 0;
  background-color: rgb(58, 40, 40);
  border-radius: 5px;
}

.s1-p4 {
  /* font-size: 16px; */
  line-height: 1.4em;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  color: rgb(210, 210, 215);
  padding: 8px 0;
  background-color: rgb(58, 40, 40);
  border-radius: 5px;
}

.s1-p5 {
  font-size: 16px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  color: rgb(74, 128, 0);
  /* width: 960px; */
  margin: 8px auto;
}


/* second section styling from here [table] */

table {
  /* width: 60%; */
  border-collapse: collapse;
  border: 1px solid lightgray;
  margin: 0 auto;
  /* background-color: white; */
}

thead th {
  text-align: left;
  padding: 10px;
}
/* 
.sh-rh {
  /* text-align: right; */
/* } */

.main-header th {
  background-color: rgb(51, 204, 153);
  color: white;
  text-align: center;
}

.main-header h1 {
  margin: 0;
}

.sub-header th {
  background-color: rgb(66, 66, 66);
  color: white;
}

tbody tr {
  border-bottom: 1px solid lightgray;
}

tbody tr:hover {
  background-color: rgb(130, 94, 94);
}

tbody td {
  padding: 10px;
}

tbody td:first-child {
  width: 50%;
}

tbody td:not(:first-child) {
  /* text-align: right; */
  white-space: nowrap;
}

a {
  color: blue;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}


/* second table styling from here */

.header-2 {
  /* background-color: #26aa26; */
  text-align: center;
  padding: 0px 0px;
  color: white;
  /* width: 59.5%; */
  /* margin: auto; */
  margin-top: 15px;
  border: 1px solid #396;
  background-color: #3c9;
  font-weight: normal;
}
table ,table tr{
  background-color: #000 I !important;
}
.table-container-2 {

  overflow-x: auto;
}

.table-2 {
  width: 100%;
  border-collapse: collapse;
  min-width: 4000px;
  /* Ensures horizontal scrolling */
}

.thead-2 {
  background-color: darkyellow;
}

.tr-2 th,
td {
  border: 1px solid #000;
  padding: 8px;
  /* text-align: left; */
  min-width: 245px;
}

th.date-column,
td.date-column {
  width: 122px;
  color: red;
}

table th {
  text-align: center !important;
}

tbody tr:nth-child(odd) {
  background-color: rgb(227, 65, 65);
}

/* tbody tr:hover {
  background-color: darkgray;
} */


/* footer button styling from here */

.table-button {
  margin-top: 50px;
  /* width: 60%;
  margin: auto; */
}

.blue-button {
  /* margin: 15px 0px; */
  display: flex;
  justify-content: space-between;
}

.blue-button .form-control,
.selection-container .form-control {
  background-color: transparent;
  border: none;
}

.blue-button button {
  font-size: 16px;
  /* margin: 7px 0px; */
  background: rgb(24, 109, 237);
  color: white;
  border: transparent;
  width: 100%;
  height: 40px;
}

.long-button {
  text-align: center;
  margin-top: 15px;
}

.long-button button {
  font-size: 16px;
  padding: 10px 60px;
  background: rgb(24, 109, 237);
  color: white;
  border: transparent;
}


/* bottom container styling from here */

.yellow-container {
  display: flex;
  justify-content: center;
  align-items: center;
  /*  height: 100vh; */
  margin-top: 20px;
  font-family: Arial, sans-serif;
  background-color: #f0f0f0;
}

.bottom-container {
  width: 100%;
  background-color: rgb(250, 196, 2);
  padding: 20px;
  text-align: center;
  border-radius: 0px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.bottom-container h1 {
  margin: 10px;
  margin-top: 0px;
  color: #0b0a0a;
  font-size: 15px;
  font-weight: 700;
}

/* .selection-container {
  display: flex;
  justify-content: space-between;
  gap: 10px;
} */

#month-select,
#year-select,
#game-select,
#go-button {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-weight: bold;
}

.css-b62m3t-container,
.css-1n6sfyn-MenuList {
  text-align: left;
}

#go-button {
  background-color: rgb(24, 109, 237);
  color: white;
  cursor: pointer;
}

#go-button:hover {
  background-color: navy;
}

.refreshButton {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  border: #e7aa26 1px solid;
  background: #FBC503;
  color: Red;
  height: auto;
  padding: 8px;
  font-weight: bold;
}


/* ============antd table styling========== */

.ant-table-thead>tr>td,
.ant-table-thead th {
  background-color: #fbc503 !important;
  /* border: 1px solid #e7aa26; */
  border-radius: 0px;
}

.ant-table-wrapper,
.ant-table-thead tr td,
.ant-table-content,
.ant-table-thead th {
  /* background: transparent !important; */
  border-bottom: 1px solid #f0f0f0;
}

/* 
.ant-table-wrapper .ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before,
.ant-table-wrapper .ant-table-thead>tr>td:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  /* background-color: transparent !important; */
/* } */ 



.ant-table-wrapper .ant-table-container table>thead>tr:first-child>*:first-child .ant-table-content {
  border-start-start-radius: 0px !important;
}

.ant-table-content td,
.ant-table-content tr,
.ant-table-content th {
  padding: 5px 0px !important;
  text-align: center !important;
color: white;
  border-right: 1px solid #ccc !important;
  vertical-align: middle !important;
}

.ant-table-content tr:nth-child(even) {
  background-color: #8c6b6b !important;
}

.ant-table-content td:hover,
/* .ant-table-content tr:hover, */
.ant-table-content th:hover{
/* background-color: #956a6a !important;
 */
 color: #000;
}
.ant-table-wrapper table {
  border-radius: 0px;
  margin-top: -6px;
}

table tbody tr:hover {
  /* background-color: #f0f0f0; */
  color: rgb(0, 0, 0);

}

/* ==========scrollbar======= */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: #33cc99;
  border-radius: 10px;

}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: #726767;
  border: 2px solid #0b7652;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  border-radius: 10px;
  background-color: #9ea1a0;
}

.game-card {
  background-color: #4e5c52; /* Success background color */
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
  
}

.game-title {
  font-size: 1.2rem;
  font-weight: bold;
}

.game-time {
  font-size: 20px;
  color: #a7ecff; /* Muted text */
  
}

.game-result {
  font-size: 1rem;
}

.previous-result {
  color: #48ff00; /* Red color for previous result */
}

.today-result {
  color: #78b9ff; /* Blue color for today's result */
}

/* advertisement section */
/* Main ad container with new background and color scheme */
.advertisementSection .ad-section {
  width: 92%;
  /* Covers 90%+ of screen width */
  margin: 20px auto;
  border: 3px solid #636362;
  /* Orange border */
  padding: 30px;
  border-radius: 12px;
  text-align: center;
  background-color: #3f3e3b;
  /* Light cream background */
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

/* Heading styles */
.advertisementSection h3 {
  color: #d35400;
  /* Darker orange */
  font-size: 30px;
  margin-bottom: 15px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.advertisementSection h4 {
  color: #1ea5f3;
  /* Dark gray text */
  font-size: 22px;
  margin: 10px 0;
}

/* Button styles */
.advertisementSection .ad-btn {
  /* display: inline-block; */
  /* padding: 10px; */
  /* margin: 5px; */
  /* font-size: 18px; */
  color: #fff;
  background-color: #e74c3c;
  border: none;
  /* border-radius: 7px; */
  /* cursor: pointer; */
  /* transition: background-color 0.3s ease; */
  text-transform: uppercase;
  text-decoration: none;
}

/* Hover effect for buttons */
.advertisementSection .ad-btn:hover {
  background-color: #c0392b;
  color: #fff;
  /* Darker red on hover */
}

.fa-whatsapp {
  background: green;
  border-radius: 65%;
}

.fa-phone {
  color: green;
  /* border: solid 1px #fff; */
  box-shadow: 0px 0px 7px #fff;
  background: #fff;
  padding: 0.1rem;
  border-radius: 5px;
}